@import "./styles/fonts/poppins.css";
@import "./styles/fonts/montserrat.css";

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: block;
  overflow: auto;
  font-family: "Montserrat", sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
}
